<template>
  <div id="home" class="white">
    <v-main>
      <v-container class="black--text mt-1 pa-4" id="home-container">
        <div id="mobile" class="hidden-md-and-up">
          <img class="imgrspm" src="../images/CTE1mbl.png" alt="slide1" />
          <img class="imgrspm" src="../images/CTE2mbl.png" alt="slide2" />
          <img class="imgrspm" src="../images/CTE3mbl.png" alt="slide3" />
          <img class="imgrspm" src="../images/CTE4mbl.png" alt="slide4" />
          <div style="background-color: #e4e5e7; font-size: 1.4rem">
            If the gate does not open using "click to enter," it might not be
            programmed. In that case, please email RFDgates@reno.gov, and it
            will be programmed. <br /><br />
            If the "Hot Keys" buttons are not programmed on the radio control
            head go to straight to 8 Law D, come to the radio shop and I can
            program the "Hot Keys" buttons.
          </div>
        </div>
        <div id="desktop" class="hidden-sm-and-down">
          <img class="imgrspm" src="../images/CTE1.png" alt="slide1" />
          <img class="imgrspm" src="../images/CTE2.png" alt="slide2" />
          <img class="imgrspm" src="../images/CTE3.png" alt="slide3" />
          <img class="imgrspm" src="../images/CTE4.png" alt="slide4" />
          <img class="imgrspm" src="../images/CTE5.png" alt="slide5" />
        </div>
      </v-container>
      <div style="visibility: hidden; width: 100%; height: 400px"></div>
    </v-main>
  </div>
</template>

<style lang="scss" scoped>
@import "../scss/site.scss";

/* 0 media q */

@media (min-width: 0px) {
  .imgrspm {
    width: 98%;
    height: auto;
    margin: 0 auto;
    margin-bottom: 10px;
  }
  #home-container {
    width: 100%;
    max-width: 1440px;
    height: auto;
    background-color: transparent;
  }
  .bold {
    font-weight: 600 !important;
    font-size: 1.2rem;
  }
  .sizeit {
    font-size: smaller !important;
  }
  p {
    font-weight: 600;
    color: black;
    font-size: 1.1rem;
    margin-top: 10px;
  }
  p.normal-text {
    font-weight: 500;
    color: black;
    font-size: 1rem;
  }
  ul {
    font-weight: 600;
    color: black;
    font-size: 1.1rem;
    margin-top: 10px;
  }
  .alice {
    color: aliceblue;
  }
}

/* 1025 media q */
@media (min-width: 768px) {
  .sizeit {
    font-size: 1rem !important;
  }
}

/* 1025 media q */
@media (min-width: 1025px) {
}

/* 2048 media q */
@media (min-width: 2048px) {
}
</style>

<script>
/* eslint-disable */
import { bus_common } from "../mixins/bus_common";

// import auth from "./auth.vue";
//import addedit_diag from "./addedit_diag.vue";
//  const addedit_diag = () => import('./addedit_diag.vue')

export default {
  name: "Home",
  components: {},
  mixins: [bus_common],
  data() {
    // Status, Fname, Lnprivateame, DOB, DIVISION
    return {
      valid: false,
      // formIn_new: {
      //   firstname: "Johntest",
      //   lastname: "Molttest",
      //   email: "jvonmolt@gmail.com",
      //   phone: "(775) 622-9020",
      //   zip: "89436",
      //   help: "Clean ALL my stuff. Rooms the size of an elephant grave yard. Hurry.",
      // },
      formIn_new: {
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        zip: "",
        help: "",
      },
      formIn: {
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        zip: "",
        help: "",
      },

      nameRules: [
        (v) => !!v || "Name is required",
        (v) => v.length <= 61 || "Name must be less than 10 characters",
      ],

      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
    };
  },
  watch: {
    "$store.state.user": function () {
      //keep this
      let self = this;
      this.init();
    },
  },
  methods: {
    /* sendRequest(rowData) {
      console.log(rowData);
    }, */

    sendemail() {
      this.show_loader(true);
      //var formIn = {};
      var formIn = this.mno(this.formIn);
      formIn.apiname = "sendemailnow";
      var self = this;

      this.$store
        .dispatch("anyapi", formIn)
        .then((data) => {
          this.swall_it(
            "Sumbit Status",
            "Your information has been sent.",
            "success",
            null,
            null
          );
        })
        .catch((msg) => {
          self.dialog = false;
          this.show_loader(false);
          this.swall_it("Error Saveing", msg, "error", null, null);
        });
    },
    scrollToElement(elementId) {
      // Get the element by its ID
      var element = document.getElementById(elementId);

      // Check if the element exists
      if (element) {
        // Scroll to the element
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      } else {
        console.error("Element not found: " + elementId);
      }
    },
    sendRequest() {
      alert("This button does nothing yet.");
    },
    message1() {
      alert("when clicked this ");
    },

    init(self) {
      this.formIn = this.mno(this.formIn_new);
      this.show_loader(false);
    },
  },
  mounted() {
    var self = this;
    this.$nextTick(() => {
      this.init(self);
    });
  },
};
</script>
